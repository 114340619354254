import React, { useContext, useRef, useState } from 'react';
import { string, shape, number, func } from 'prop-types';
import classnames from 'classnames';
import { Card as Polycard } from '@polycard/card';

import usePolyCardInteraction from '../../../hooks/use-polycard-interaction';
import { StaticPropsContext } from '../../context/static-props';
import { DEVICE_TYPES } from '../../../constants';
import useCartHandlers from '../../card/add-to-cart/hooks/useCartHandlers';
import findPolycardComponent from '../../../utils/find-polycard-components';

const OneColumnItemLayoutPolycard = ({ item, index, layout, onMount }) => {
  const namespace = 'ui-search-result';
  const { deviceType, vertical, site } = useContext(StaticPropsContext);

  const cartComponent = findPolycardComponent(item, 'add_to_cart') || {};
  const {
    metadata: {
      id,
      pictures: { variation_id = '' } = {},
      product_id,
      is_add,
      inventory_id,
      category_id,
      should_show_suggestions,
      group_by,
      tracks: { cart_tracks = undefined } = {},
    } = {},
  } = item?.polycard || {};
  const {
    add_to_cart: {
      cart_status: { quantity = 0, text = '' } = {},
      cart_shipping: { cart_free_shipping = 0 } = {},
      cart_config: { available_quantity = 0, min_add_quantity = 0, already_has_suggestions } = {},
    } = {},
  } = cartComponent;

  const cardRef = useRef(null);
  const [mouseEntered, setMouseEntered] = useState(false);

  const isGrid = layout === 'grid';
  const isCPG = vertical === 'CPG';
  const isMobile = deviceType === DEVICE_TYPES.MOBILE;
  const layoutIndex = isGrid || isMobile ? index : index + 1;

  const { hasFooter, hasVariations, itemState, loadImagesCarousel, handleBookmark } = usePolyCardInteraction({
    item,
    cardRef,
    onMount,
    isMobile,
    layout,
  });

  const { handleAddToCartPut, handleAddToCartDelete } = useCartHandlers({
    availableQuantity: available_quantity,
    minimumQuantity: min_add_quantity,
    itemId: id,
    category: category_id,
    cart_request_initial_delay: null,
    cart_request_delay: 500,
    should_call_suggestions: should_show_suggestions,
    variation_id,
    isCpg: isCPG,
    productId: product_id,
    inventoryId: inventory_id,
    weight_data: undefined, // pendiente por enviar para pesables
    tracks: cart_tracks,
    groupBy: group_by,
    threshold: cart_free_shipping,
    viewMode: layout,
    isAd: is_add,
    label_count: text,
    quantity,
    isPolycard: true,
  });

  const isResizable = !isMobile && isGrid;
  const hiddenPicture = hasVariations && itemState.pictures.pictures.length > 1;

  const className = classnames({
    [`${namespace}`]: isMobile && !isCPG,
    [`${namespace}__wrapper`]: !isMobile,
    [`${namespace}__wrapper--hidden_picture`]: hiddenPicture,
    [`${namespace}__wrapper--large`]: (hasFooter && isResizable) || (hasVariations && isResizable),
    '': isCPG,
  });

  const polycardClass = classnames({
    'mshops-search-polycard': site === 'MS',
  });

  const handleMouseEnter = () => {
    if (!mouseEntered) {
      loadImagesCarousel(itemState.pictures.variation_id, true);
      setMouseEntered(true);
    }
  };

  const addToCartObj = {
    handleAddToCartPut: (e, url) => handleAddToCartPut(e, url),
    handleAddToCartDelete: (e, url) => handleAddToCartDelete(e, url),
  };

  return (
    <div className={className} ref={cardRef}>
      <Polycard
        key={itemState?.metadata?.id}
        polycard={itemState}
        addToCartActions={addToCartObj}
        cardNumber={layoutIndex}
        bookmarkAction={handleBookmark}
        pickerAction={loadImagesCarousel}
        onMouseEnter={isGrid && handleMouseEnter}
        className={polycardClass}
        suggestions={already_has_suggestions}
      />
    </div>
  );
};

OneColumnItemLayoutPolycard.propTypes = {
  index: number.isRequired,
  item: shape({
    title: string,
  }).isRequired,
  layout: string.isRequired,
  onMount: func,
};

export default OneColumnItemLayoutPolycard;
