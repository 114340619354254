import React from 'react';
import { string, bool } from 'prop-types';

import { Head } from 'nordic/head';
import isEmpty from 'lodash/isEmpty';

const Metadata = ({
  canonical,
  description,
  title,
  noIndex = false,
  should_add_none_to_response = false,
  keywords = undefined,
  image = 'https://http2.mlstatic.com/frontend-assets/homes-palpatine/logo_homecom_v2.png',
}) => (
  <Head>
    {!isEmpty(title) && <title>{title}</title>}
    {!isEmpty(title) && <meta name="og:title" content={title} />}
    <meta name="og:type" content="website" />
    {!isEmpty(description) && <meta name="description" content={description} />}
    {noIndex && <meta name="robots" content="noindex" />}
    {!isEmpty(keywords) && <meta name="keywords" content={keywords} />}
    {!isEmpty(canonical) && <link rel="canonical" href={canonical} title={title} />}
    {!isEmpty(canonical) && <meta name="og:url" content={canonical} />}
    {should_add_none_to_response && <meta name="robots" content="noindex, nofollow" />}
    {image && <meta name="og:image" content={image} />}
    <link rel="alternate" href={`android-app://com.mercadolibre/meli/search?go=${canonical}`} title={title} />
    <link rel="alternate" href={`ios-app://463624852/meli/search?go=${canonical}`} title={title} />
  </Head>
);

Metadata.propTypes = {
  canonical: string.isRequired,
  description: string.isRequired,
  image: string,
  keywords: string,
  noIndex: bool,
  should_add_none_to_response: bool,
  title: string.isRequired,
};

export default Metadata;
