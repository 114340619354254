/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */

import React, { useContext, useEffect } from 'react';
import { string, shape, func, number, PropTypes } from 'prop-types';

import classnames from 'classnames';
import { Button } from '@andes/button';

import IconFull from '../icons/full';
import ShippingTruck from '../icons/shipping-truck';
import IconEshop from '../icons/eshop';
import IconClose from '../icons/close';
import { FullFreeshippingContext } from '../context/full-freeshipping';
import IconSupermarketFS from '../icons/supermarket-fs';
import ProgressBar from './progress-bar-default';
import FullShippingInfoSkeleton from './full-shipping-info-skeleton';
import useFullFreeshipping from './use-full-freeshipping';
import IconFactoryBuilder from '../icons/factory-builder';
import IconFullSuperSmall from '../icons/full-super-small';
import IconFullSuperSmallMlb from '../icons/full-super-small-mlb';

const IconFactory = IconFactoryBuilder([IconSupermarketFS, IconFull, IconFullSuperSmall, IconFullSuperSmallMlb]);

const namespace = 'ui-search-fullsnackbar';

const getTypeFromProgressBar = (progress_bar) => {
  if (!progress_bar) {
    return 'zero';
  }

  switch (progress_bar.color) {
    case 'neutral':
      if (progress_bar.percent > 0) {
        return 'progress';
      }

      return 'zero';
    case 'success':
      return 'complete';
    case 'warning':
      return 'error';

    default:
      return 'zero';
  }
};

const IconEshopWrapper = ({ actionLogo, children, withLink }) => {
  if (withLink && actionLogo && actionLogo.permalink) {
    return <a href={actionLogo.permalink}>{children}</a>;
  }

  return children;
};

IconEshopWrapper.propTypes = {
  actionLogo: PropTypes.shape({
    permalink: PropTypes.string,
  }),
  children: PropTypes.node,
  withLink: PropTypes.bool,
};

const FullShippingInfoContent = ({
  assetUrl,
  closeAction,
  epigraph,
  firstText,
  lastText,
  progressBar,
  title,
  action_button,
  action_logo,
}) => {
  const { showShimmerAction } = useContext(FullFreeshippingContext);

  return (
    <>
      <div className={`${namespace}__main`}>
        <div className={`${namespace}__icon-text-container`}>
          <div className={`${namespace}__icon-container`}>
            {assetUrl && (
              <div className={`${namespace}__eshop-container`}>
                <IconEshopWrapper actionLogo={action_logo} withLink={showShimmerAction}>
                  <IconEshop image={assetUrl} />
                </IconEshopWrapper>
              </div>
            )}
            {!assetUrl && title && (
              <div
                className={`${namespace}__shippingtruck-container`}
                style={{ backgroundColor: progressBar?.color_hex }}
              >
                <ShippingTruck />
              </div>
            )}
          </div>
          <div className={`${namespace}__text-container`}>
            {title && (
              <div className={`${namespace}__top`}>
                {title.first_text && <span className={`${namespace}__preicon`}>{firstText}</span>}
                {title.asset_image && IconFactory(title.asset_image)}
                {title.last_text && <span className={`${namespace}__posticon`}>{lastText}</span>}
              </div>
            )}
            {progressBar?.percent > 0 && <ProgressBar progress_bar={progressBar} namespace={namespace} />}
            {epigraph?.first_text && (
              <div className={`${namespace}__bottom`}>
                <span className={`${namespace}__subtext`} dangerouslySetInnerHTML={{ __html: epigraph.first_text }} />
              </div>
            )}
          </div>
        </div>
        <div className={`${namespace}__button-container`}>
          {showShimmerAction && (
            <Button
              className={`${namespace}__action-button`}
              size="medium"
              href={action_button?.permalink}
              type="button"
            >
              {action_button?.text}
            </Button>
          )}
        </div>
      </div>
      {closeAction && (
        <button className={`${namespace}__close`} onClick={closeAction} type="button" tabIndex={0}>
          <span className="sr-only">{}</span>
          <IconClose />
        </button>
      )}
    </>
  );
};

FullShippingInfoContent.propTypes = {
  action_button: shape({
    text: string,
    permalink: string,
  }),
  action_logo: shape({
    permalink: string,
  }),
  assetUrl: string,
  closeAction: func,
  epigraph: shape({
    first_text: string,
    last_text: string,
  }),
  firstText: string,
  lastText: string,
  progressBar: shape({
    percent: number,
    color: string,
    color_hex: string,
  }),
  title: shape({
    first_text: string,
    last_text: string,
    asset_image: string,
  }),
};

FullShippingInfoContent.defaultProps = {
  closeAction: null,
  epigraph: null,
  progress_bar: null,
  title: null,
  variant: 'default',
};

const FullSnackbar = ({ className, variant, closeAction }) => {
  const { fullOnlyFirstFreeshipping, setFullOnlyFirstFreeshipping } = useContext(FullFreeshippingContext);
  const {
    currentFullFreeshipping: {
      asset_url,
      className: contextClassName,
      epigraph,
      progress_bar,
      title,
      action_button,
      action_logo,
    },
    isLoading,
  } = useFullFreeshipping();

  const sucess = progress_bar?.color === 'success';
  const warning = progress_bar?.color === 'warning';
  const firstText = title?.first_text?.trim();
  const lastText = title?.last_text?.trim();
  const currentClassName = className || contextClassName;

  useEffect(() => {
    if (fullOnlyFirstFreeshipping) {
      setFullOnlyFirstFreeshipping(
        (sucess && fullOnlyFirstFreeshipping.successFirst) || (warning && fullOnlyFirstFreeshipping.warningFirst),
      );
    }

    return () => {
      if (sucess || warning) {
        setFullOnlyFirstFreeshipping({ successFirst: sucess, warningFirst: warning });
      }
    };
  }, [progress_bar]);

  const props = {
    epigraph,
    progressBar: progress_bar,
    variant,
    assetUrl: asset_url,
    firstText,
    lastText,
    title,
    action_button,
    action_logo,
  };

  return (
    <div
      className={classnames(
        namespace,
        currentClassName,
        `ui-search-fullsnackbar--${getTypeFromProgressBar(progress_bar)}`,
        {
          [`${namespace}--complete-first`]: fullOnlyFirstFreeshipping,
        },
      )}
    >
      {isLoading ? <FullShippingInfoSkeleton /> : <FullShippingInfoContent {...props} closeAction={closeAction} />}
    </div>
  );
};

FullSnackbar.propTypes = {
  className: string,
  closeAction: func,
  variant: string,
};

FullSnackbar.defaultProps = {
  className: null,
  variant: 'default',
};

export default FullSnackbar;
