/* eslint-disable consistent-return */
import { useLayoutEffect, useState, useEffect, useCallback, useContext } from 'react';

import { UserContext } from '../components/context/user';
import APIServiceImage from '../../services/picturesCarousel';
import APIServiceBookMark from '../../services/bookmarks';

const redirect = (loginUrl) => {
  window.location.href = loginUrl;
};

const usePolyCardInteraction = ({ item, cardRef, onMount, isMobile, layout }) => {
  const [hasFooter, setHasFooter] = useState(false);
  const [hasVariations, setHasVariations] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [itemState, setItem] = useState(item?.polycard);
  const [loadedItems, setLoadedItems] = useState(new Set());
  const [isFetching, setIsFetching] = useState(false);
  const { loggedIn, loginUrl } = useContext(UserContext);

  useLayoutEffect(() => {
    if (cardRef?.current && onMount) {
      const imageWrapper = cardRef.current.querySelector('.poly-card__portada');
      const contentWrapper = cardRef.current.querySelector('.poly-card');
      const imageHeight = imageWrapper?.getBoundingClientRect().height;
      const contentHeight = contentWrapper.getBoundingClientRect().height - imageHeight;

      onMount({ imageHeight, contentHeight });
    }
  }, [cardRef, onMount]);

  useEffect(() => {
    const footer = cardRef?.current?.getElementsByClassName('poly-card__footer');
    const variations = cardRef?.current?.getElementsByClassName('poly-component__variations');

    setHasFooter(!!footer?.length);
    setHasVariations(!!variations?.length);
  }, [cardRef]);

  useEffect(() => {
    if (isMobile && layout === 'gallery') {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.2,
      };

      const observer = new IntersectionObserver(([entry]) => {
        setIsVisible(entry.isIntersecting);
      }, options);

      const currentRef = cardRef.current;

      if (currentRef) {
        observer.observe(currentRef);
      }

      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }
  }, [cardRef, isMobile, layout]);

  const handleBookmark = async (e, itemId) => {
    e.preventDefault();

    if (isFetching) {
      return;
    }

    if (!loggedIn) {
      redirect(loginUrl);

      return;
    }

    setIsFetching(true);

    try {
      const updatedBookmarkState = !itemState.bookmark.bookmarked;

      setItem({
        ...itemState,
        bookmark: {
          bookmarked: updatedBookmarkState,
        },
      });

      await APIServiceBookMark.toggleBookmarkService(itemId, updatedBookmarkState);
    } catch (ex) {
      if (ex.response && ex.response.status === 403) {
        redirect(loginUrl);

        return;
      }

      setItem({
        ...itemState,
        bookmark: {
          bookmarked: itemState.bookmark.bookmarked,
        },
      });
    } finally {
      setIsFetching(false);
    }
  };

  const loadImagesCarousel = useCallback(
    async (variation_id = null, isMouseEvent = false) => {
      const polyItem = { ...itemState };

      if (
        (!hasVariations && isMobile && loadedItems.has(polyItem.metadata.id)) ||
        (hasVariations && isMobile) ||
        isFetching ||
        layout === 'stack'
      ) {
        return;
      }

      setIsFetching(true);

      if (isMouseEvent) {
        polyItem.metadata.isLoading = true;
        setItem(polyItem);
      }

      try {
        const pictures = await APIServiceImage.getCarouselPictures(
          polyItem.metadata.id,
          variation_id || polyItem.pictures.variation_id || '',
          true,
        );

        if (pictures[0]?.pictures?.length >= 1) {
          const polyPictures = polyItem.pictures.pictures;
          const pictureList = pictures[0].pictures;

          if (pictureList[0].id !== polyPictures[0].id && !hasVariations) {
            pictureList.unshift(polyPictures[0]);
          }

          polyItem.pictures = { ...polyItem.pictures, ...pictures[0] };
        }

        polyItem.metadata.isLoading = false;
      } catch (err) {
        setIsFetching(false);
        setItem(polyItem);
      } finally {
        setIsFetching(false);
        setItem(polyItem);
        setLoadedItems(new Set(loadedItems).add(polyItem.metadata.id));
      }
    },
    [itemState, layout, loadedItems, isFetching, hasVariations, isMobile],
  );

  useEffect(() => {
    if (isVisible) {
      loadImagesCarousel();
    }
  }, [isVisible, loadImagesCarousel]);

  return {
    hasFooter,
    hasVariations,
    itemState,
    isVisible,
    isFetching,
    loadImagesCarousel,
    handleBookmark,
    setIsVisible,
    setIsFetching,
  };
};

export default usePolyCardInteraction;
