import React, { useState } from 'react';
import { node } from 'prop-types';

import get from 'lodash/get';
import findPolycardComponent from '../../../utils/find-polycard-components';

const SearchContext = React.createContext(null);
const { Provider, Consumer: SearchConsumer } = SearchContext;
const SearchProvider = ({ children, ...rest }) => {
  const repeatProductObjet = {};
  const cards = [];

  const getProperty = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj);

  const getRepeatProductObjet = (results = []) => {
    const duplicatedCards = [];

    const repeatProducts = results.filter((obj, index) => {
      const prop = !!obj.polycard ? 'polycard.metadata.id' : 'id';

      if (obj.id === 'CART_INTERVENTION') {
        duplicatedCards.push(...obj.dynamic_access.cards);
      }

      if (results.findIndex((it) => getProperty(it, prop) === getProperty(obj, prop)) !== index) {
        return true;
      }

      return false;
    });

    duplicatedCards.forEach((card) => {
      const item = results.find((it) => card.id === it.id);

      if (item) {
        repeatProducts.push(item);
      }
    });

    // eslint-disable-next-line array-callback-return
    repeatProducts.map((it) => {
      if (it.cpg) {
        repeatProductObjet[it.id] = {
          quantity: it.cpg.quantity,
          label_count: it.cpg.label_count,
          max_stock: it.available_quantity,
        };
      }

      if (it.polycard) {
        const polyId = it?.polycard?.metadata?.id;
        const polycardComponent = findPolycardComponent(it, 'add_to_cart');

        if (polycardComponent && polycardComponent.add_to_cart) {
          const {
            add_to_cart: {
              cart_status: { quantity, text },
              cart_config: { available_quantity },
            },
          } = polycardComponent;
          repeatProductObjet[polyId] = {
            quantity,
            label_count: text,
            max_stock: available_quantity,
          };
        }
      }
    });

    return repeatProductObjet;
  };

  const [sameItemList, setSameItemList] = useState(getRepeatProductObjet(rest.results));

  function getItemById(itemId) {
    const resultItem = rest.results.find((it) => {
      if (it.id === 'BILLBOARD_INTERVENTION') {
        return it.contents;
      }

      if (it.id === 'CART_INTERVENTION') {
        cards.push(...it.dynamic_access.cards);
      }

      const item = it?.polycard?.metadata?.id === itemId || it?.id === itemId;

      return item;
    });

    if (!resultItem && rest.billboard && rest.billboard.items) {
      return rest.billboard.items.find((it) => it.id === itemId);
    }

    const card = cards.find((item) => item.id === itemId);

    if (!resultItem && card) {
      return card.content.add_to_cart_item;
    }

    return resultItem;
  }

  function removeSuggestionItemPrevious() {
    rest.results.some((item) => {
      if (item?.polycard) {
        const polycardComponent = findPolycardComponent(item, 'add_to_cart');

        if (polycardComponent?.add_to_cart?.cart_config?.already_has_suggestions) {
          polycardComponent.add_to_cart.cart_config.already_has_suggestions = undefined;
          return true;
        }
      }
      return false;
    });
  }

  const updatePolyCartValues = (item, mainProperty, secondProperty, value) => {
    const addToCartPoly = findPolycardComponent(item, 'add_to_cart');

    if (addToCartPoly?.add_to_cart?.[mainProperty]) {
      addToCartPoly.add_to_cart[mainProperty][secondProperty] = value;
    }
  };

  const updateQuantityRepeatProduct = (item, value) => {
    rest.results.forEach((element) => {
      const addToCartPoly = findPolycardComponent(element, 'add_to_cart')?.add_to_cart;
      if (element.polycard?.metadata.id === item.polycard?.metadata.id) {
        addToCartPoly.cart_status.quantity = value;
      }
    });
  };

  const findSameQuantity = (quantity) => (finding) => finding.quantity === quantity;

  const state = {
    ...rest,
    sameItemList,
    setSameItemList,
    toggleBookmark: (itemId) => {
      const item = getItemById(itemId);

      let bookmarked = get(item, 'bookmarked');

      if (Array.isArray(item.contents)) {
        item.contents.map((itemValue) => {
          if (itemValue.item_id === itemId) {
            bookmarked = get(itemValue, 'bookmarked');
            itemValue.bookmarked = !bookmarked;
          }

          return !bookmarked;
        });
      }

      item.bookmarked = !bookmarked;

      return !bookmarked;
    },
    setQuantityCart: (itemId, quantity) => {
      const item = getItemById(itemId);
      if (item?.polycard) {
        updatePolyCartValues(item, 'cart_status', 'quantity', quantity);
        updateQuantityRepeatProduct(item, quantity);
        return;
      }
      item.cpg.quantity = quantity;
    },
    setLabelCount: (itemId, label) => {
      const item = getItemById(itemId);

      item.cpg.label_count.text = label.text;
      item.cpg.label_count.values = label.values;
    },
    setLabelCountValues: (itemId, values) => {
      const item = getItemById(itemId);

      item.cpg.label_count.values = values;
    },
    getQuantityCart: (itemId) => {
      const item = getItemById(itemId);
      if (item?.polycard) return findPolycardComponent(item, 'add_to_cart').add_to_cart.cart_status.quantity;
      return item.cpg.quantity;
    },
    setQuantityCartRequested: (itemId, quantity) => {
      const item = getItemById(itemId);
      if (item?.polycard) {
        updatePolyCartValues(item, 'cart_status', 'quantity_requested', quantity);
        return;
      }

      item.cpg.quantityRequested = quantity;
    },
    getQuantityCartRequested: (itemId) => {
      const item = getItemById(itemId);
      if (item?.polycard) return findPolycardComponent(item, 'add_to_cart').add_to_cart.cart_status.quantity_requested;

      return item.cpg.quantityRequested;
    },
    setQuantityCartPrevious: (itemId, quantity) => {
      const item = getItemById(itemId);
      if (item?.polycard) {
        updatePolyCartValues(item, 'cart_config', 'quantity_Previous', quantity);
        return;
      }
      if (item?.cpg) item.cpg.quantityPrevious = quantity;
    },
    getQuantityCartPrevious: (itemId) => {
      const item = getItemById(itemId);
      if (item?.polycard) return findPolycardComponent(item, 'add_to_cart').add_to_cart.cart_config.quantity_Previous;

      return item.cpg.quantityPrevious;
    },
    setPreloadedLabelCount: (itemId, preloaded) => {
      const item = getItemById(itemId);

      if (!item.cpg.preloaded_label_counts) {
        item.cpg.preloaded_label_counts = [];
      }

      preloaded.forEach((element) => {
        const position = item.cpg.preloaded_label_counts.findIndex(findSameQuantity(element.quantity));

        if (position !== -1) {
          item.cpg.preloaded_label_counts.splice(position, 1);
        }

        item.cpg.preloaded_label_counts.push(element);
      });
    },
    setPartialPreloadedLabelCount: (itemId, preloaded) => {
      const item = getItemById(itemId);

      if (!item.cpg.preloaded_label_counts) {
        item.cpg.preloaded_label_counts = [];
      }

      preloaded.forEach((element) => {
        const position = item.cpg.preloaded_label_counts.findIndex((it) => it.quantity === element.quantity);

        const selectedPreloaded = item.cpg.preloaded_label_counts[position];

        if (selectedPreloaded) {
          const values = selectedPreloaded?.values.map((it) => {
            if (it.key === 'quantity_text') {
              return element.values.filter((val) => val.key === 'quantity_text')[0];
            }

            return it;
          });

          if (values.length > 0) {
            item.cpg.preloaded_label_counts[position].values = values;
          }
        } else {
          item.cpg.preloaded_label_counts.push(element);
        }
      });
    },
    getPreloadedLabelCount: (itemId, quantity) => {
      const item = getItemById(itemId);

      return item?.cpg?.preloaded_label_counts?.find(findSameQuantity(quantity)) ?? null;
    },
    getLoadedSuggestions: (itemId) => {
      const item = getItemById(itemId);

      if (item?.polycard) {
        return findPolycardComponent(item, 'add_to_cart').add_to_cart?.cart_config?.already_has_suggestions;
      }

      return item?.cpg?.alreadyHasSuggestions;
    },
    setLoadedSuggestions: (itemId, value) => {
      const item = getItemById(itemId);
      removeSuggestionItemPrevious();

      if (item?.polycard) {
        updatePolyCartValues(item, 'cart_config', 'already_has_suggestions', value);
        return;
      }

      item.cpg.alreadyHasSuggestions = value;
    },
    getCPGInfo: (itemId) => {
      const item = getItemById(itemId);

      return item.cpg ?? item.polycard;
    },
    getSuggestionsAlreadyOpened: (itemId) => {
      const item = getItemById(itemId);
      if (item?.polycard) {
        return findPolycardComponent(item, 'add_to_cart').add_to_cart?.cart_config?.suggestions_already_opened;
      }

      return item?.cpg?.suggestionsAlreadyOpened;
    },
    setSuggestionsAlreadyOpened: (itemId, value) => {
      const item = getItemById(itemId);

      if (item?.polycard) {
        updatePolyCartValues(item, 'cart_config', 'suggestions_already_opened', value);
        return;
      }

      item.cpg.suggestionsAlreadyOpened = value;
    },
    setThresHoldPoly: (threshold_labels, groupBy) => {
      rest.results.forEach((item) => {
        const { polycard } = item || {};
        const polycardComponent = polycard && findPolycardComponent(item, 'add_to_cart');
        const { cart_shipping: cartShipping } = polycardComponent?.add_to_cart || {};
        const polyGroupBy = polycard?.metadata?.group_by;

        if (cartShipping && threshold_labels) {
          const { id_threshold, text: currentText, styles: currentStyles } = cartShipping;
          const threshold_label = threshold_labels.find(
            ({ polycard_label }) => polycard_label?.id_threshold === id_threshold,
          )?.polycard_label;

          const hasDifferentText = threshold_label?.text !== currentText;
          const hasDifferentColor = threshold_label?.styles?.color_hex !== currentStyles?.color_hex;

          if ((hasDifferentText || hasDifferentColor) && groupBy === polyGroupBy) {
            polycardComponent.add_to_cart.cart_shipping = threshold_label;
          }
        }
      });
    },
  };

  return <Provider value={state}>{children}</Provider>;
};

SearchProvider.propTypes = {
  children: node.isRequired,
};

export { SearchContext, SearchProvider, SearchConsumer };
