/* eslint-disable  @typescript-eslint/default-param-last */
/* eslint-disable complexity */
import APIService from '../../../../../services/cart';
import { sendTracks, setMelidataInfoRemove, setMelidataInfoAdd, ACTION } from '../utils';

const ServiceAddToCart = (
  itemId,
  category,
  variation_id,
  availableQuantity,
  quantityCart,
  isCpg,
  productId,
  inventoryId,
  add,
  parentHooks,
  queryParams,
  weight_data,
  requestQueue = {},
  tracks,
  updateCartFreya,
  firstRequest,
  groupBy,
  threshold,
  viewMode,
  isEshopsEnvironment,
  isAd,
  permalink,
  previousQuantity,
) => {
  const {
    setFullFreeshipping,
    setFullFreeShippingLoading,
    setPreloadedLabelCount,
    setQuantityCartRequested,
    apiError,
    processRequest,
    checkAvailableQuantity,
    changeFreeShippingText,
    setFirstRequest,
    setMaxStock,
    setPartialPreloadedLabelCount,
    setCurrentInterventionData,
    setQuantityCartPrevious,
    setThresHoldPoly,
  } = parentHooks;

  const request = () => {
    if (requestQueue) {
      requestQueue.isRequestInProgress = true;
    }

    APIService.addToCart(
      itemId,
      quantityCart,
      category,
      variation_id,
      availableQuantity,
      add,
      isCpg,
      queryParams,
      productId,
      inventoryId,
      weight_data,
      firstRequest,
      groupBy,
      threshold,
      viewMode,
      previousQuantity,
    )
      .then((response) => {
        const callback = () => {
          const { components, modify_cart_cpg } = response.data;

          const { add_to_cart, cart_info, modify_cart, snackbar, intervention_info } =
            components?.[0] || modify_cart_cpg;

          setCurrentInterventionData(intervention_info);

          let fullFreeShipping;

          if (cart_info || modify_cart) {
            fullFreeShipping = cart_info?.free_shipping || modify_cart?.cart_info?.free_shipping;
          }

          const addToCartResponse = add_to_cart || modify_cart;

          if (addToCartResponse) {
            sendTracks(addToCartResponse);

            if (addToCartResponse.preloaded_label_counts) {
              if (viewMode === 'intervention') {
                setPartialPreloadedLabelCount(itemId, addToCartResponse.preloaded_label_counts);
              } else {
                setPreloadedLabelCount(itemId, addToCartResponse.preloaded_label_counts);
              }
            }
          }

          if (snackbar) {
            apiError(snackbar);
          }

          if (fullFreeShipping) {
            setFullFreeshipping({ ...fullFreeShipping, key: Math.floor(Math.random() * 1000) });
            setFullFreeShippingLoading(false);
          } else if (isEshopsEnvironment && !snackbar) {
            setFullFreeshipping(null);
          }

          if (isAd) {
            /* istanbul ignore next */
            const action = add === ACTION.ADD ? 'add' : 'remove';

            /* istanbul ignore next */
            APIService.sendPermalink(permalink, action);
          }

          if (add === ACTION.ADD) {
            setMelidataInfoAdd(quantityCart, tracks);
          } else {
            setMelidataInfoRemove(quantityCart, tracks);
          }

          const { available_quantity } = components[0]?.add_to_cart || {};

          if (available_quantity) {
            if (quantityCart >= available_quantity) {
              checkAvailableQuantity(available_quantity);
              setQuantityCartRequested(itemId, available_quantity);
            }

            setMaxStock(available_quantity);
          } else {
            setQuantityCartPrevious(itemId, quantityCart);
          }

          /* istanbul ignore next */
          setFirstRequest(false);

          if (cart_info?.threshold_label_state) {
            changeFreeShippingText(cart_info);
          }

          if (cart_info?.threshold_label_state && setThresHoldPoly) {
            setThresHoldPoly(cart_info?.threshold_labels, groupBy);
          }

          updateCartFreya();
        };

        processRequest(callback);
      })
      .catch(() => {
        if (requestQueue) {
          requestQueue.isRequestInProgress = false;
        }

        setFullFreeShippingLoading(false);
        apiError();
      });
  };

  if (requestQueue?.isRequestInProgress) {
    requestQueue.pendingRequest = request;
  } else {
    request();
  }
};

export default ServiceAddToCart;
