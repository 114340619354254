import React from 'react';

import IconFull from '../icons/full';
import IconFullSuper from '../icons/full-super';
import IconFullSuperMlb from '../icons/full-super-mlb';
import IconMelicoin from '../icons/icon-melicoin';
import IconVirtualTour from '../icons/virtual-tour';
import IconMelichoice from '../icons/melichoice';
import IconInternationalFilter from '../icons/international-filter-cbt';
import MeliPlusIcon from '../icons/meli-plus';
import IconInternationalFilterMobile from '../icons/international-filter-cbt-mobile';

const renderIcon = (type) => {
  switch (type) {
    case 'fulfillment':
      return <IconFull />;
    case 'full_super':
      return <IconFullSuper />;
    case 'full_super_mlb':
      return <IconFullSuperMlb />;
    case 'melicoin':
      return <IconMelicoin />;
    case 'virtual_tour_icon':
      return <IconVirtualTour />;
    case 'cbt_international_desktop':
      return <IconInternationalFilter />;
    case 'cbt_international_mobile':
      return <IconInternationalFilterMobile />;
    case 'meli_plus_icon':
      return <MeliPlusIcon />;
    case 'melichoice_icon':
      return <IconMelichoice />;

    default:
      return null;
  }
};

export default renderIcon;
